import { notEmptyString, validEmail } from "../validators";

export class UserAccount {
  _id = "";
  nickName = "";
  fullName = "";
  identifier = "";
  roles: string[] = [];
  createdAt = new Date();
  modifiedAt = new Date();

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        if (typeof v === "string") {
          switch (k) {
            case "_id":
            case "nickName":
            case "fullName":
              this[k] = v;
              break;
            case "identifier":
            case "email":
              this.identifier = v;
              break;
            case "createdAt":
            case "modifiedAt":
              this[k] = new Date(v);
              break;
          }
        } else if (v instanceof Array && k === "roles") {
          this.roles = v;
        }
      });
    }
  }

  get hasIdentifier(): boolean {
    return validEmail(this.identifier);
  }

  get id(): string {
    return this._id;
  }

  setId(id = ""): UserAccount {
    if (notEmptyString(id)) {
      this._id = id;
    }
    return this;
  }
}
