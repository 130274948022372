import { isNumeric } from "./validators";

export const capitalize = (str: string): string => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

export const smartCastFloat = (inval: any): number => {
  let out = 0;
  if (isNumeric(inval)) {
    if (typeof inval === "string") {
      out = parseFloat(inval);
    } else if (typeof inval === "number") {
      out = inval;
    }
  }
  return out;
};

export const smartCastInt = (inval: any): number => {
  return Math.floor(smartCastFloat(inval));
};
