export class Role {
  key = "";
  name = "";
  adminAccess = false;

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        if (typeof v === "string") {
          switch (k) {
            case "key":
            case "name":
              this[k] = v;
              break;
          }
        } else if (typeof v === "boolean" && k == "adminAccess") {
          this.adminAccess = v;
        }
      });
    }
  }

  get isPaid(): boolean {
    return this.name.startsWith("member_");
  }
}
