import axios from "axios";
import { api } from "../.config";
import { buildOptions } from "./build-headers";
import { fromLocal, toLocal } from "./localstore";
import { AccountPayload } from "./interfaces";

const extractDataObj = (res: any) => {
  if (res instanceof Object) {
    const { data } = res;
    if (data instanceof Object || data instanceof Array) {
      return data;
    }
  }
};

const postData = async (
  path: string,
  params: any = null,
  callback: any = null,
  putMode = false
) => {
  let result: any = {};
  const isFormData = params instanceof FormData;
  const func = putMode !== true ? axios.post : axios.put;
  await func(`${api.base}${path}`, params, buildOptions(isFormData, callback))
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      result.error = e;
    });
  return result;
};

const putData = async (
  path: string,
  params: any = null,
  callback: any = null
) => {
  return postData(path, params, callback, true);
};

/* const putData = async (path: string, params = null, callback: any = null) => {
  return postData(path, params, callback, true);
}; */

const fetchData = async (path: string, deleteMode = false, userId = "") => {
  let result: any = {};
  const func = deleteMode !== true ? axios.get : axios.delete;
  await func(`${api.base}${path}`, buildOptions(false, null, userId))
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      result.error = e;
    });
  return result;
};

const fetchContent = async (path = "", userId = "") => {
  return fetchData(path, false, userId);
};

/* const buildQueryString = (criteria: any = null, literal = false) => {
  let str = "";
  const specialRgx = /[\\/&? ]/;
  if (criteria instanceof Object) {
    const parts: Array<string> = [];
    Object.entries(criteria).forEach((entry) => {
      const [key, val] = entry;
      let paramVal = val;
      if (typeof val === "string") {
        if (!specialRgx.test(val)) {
          literal = true;
        }
        paramVal = literal ? val : encodeURIComponent(val);
      } else if (typeof val === "number" || typeof val === "boolean") {
        paramVal = val.toString();
      } else if (val instanceof Array) {
        paramVal = val.join(",");
      }
      parts.push(key + "=" + paramVal);
    });
    if (parts.length > 0) {
      str = "?" + parts.join("&");
    }
  }
  return str;
}; */

export const getData = async (path: string) => {
  let data = { valid: false };
  await fetchContent(path).then((response) => {
    if (response.data) {
      data = response.data;
      data.valid = true;
    }
  });
  return data;
};

export const fetchDataObject = async (
  path: string,
  userId = ""
): Promise<any> => {
  let data: any = { valid: false };
  await fetchContent(path, userId).then((res) => {
    const result = extractDataObj(res);
    if (result instanceof Object) {
      data = result;
    }
  });
  return data;
};

export const fetchRoleOptions = async () => {
  const cKey = "role-options";
  const stored = fromLocal(cKey, 3600);
  if (!stored.expired) {
    const data = await fetchRemoteRoleOptions();
    toLocal(cKey, data);
    return data;
  } else {
    return stored.data;
  }
};

export const fetchRemoteRoleOptions = async () => {
  const path = "user/role-options";
  return await fetchDataObject(path);
};

export const resetPass = async (hash = "", payload: AccountPayload) => {
  const path = ["user", "reset-pass", hash].join("/");
  const response = await putData(path, payload);
  const result = { valid: false };
  if (response instanceof Object) {
    const { data } = response;
    if (data instanceof Object) {
      return data;
    }
  }
  return result;
};

export const fetchUserFromHash = async (hash = "") => {
  const path = ["user", "reset", hash].join("/");
  return await fetchDataObject(path);
};
